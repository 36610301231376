import './App.css';
import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import About from './components/About';
import NotFound from './errorPages/NotFound';
import {BrowserRouter as Router, Routes, Route, useLocation} from 'react-router-dom';
import Contact from './components/Contact';
import Projects from "./components/Projects";

const App: React.FC = () => {

    return (
        <>
            <main className='main'>
                <Router>
                    <Header/>
                    <Routes> {}
                        <Route path='/' element={<Main/>}/>
                        <Route path='/about' element={<About/>}/>
                        <Route path='/projects' element={<Projects />} />
                        <Route path='/contact' element={<Contact/>}/>
                        <Route path='*' element={<NotFound/>}/>
                    </Routes>
                </Router>
            </main>
        </>
    );
};

export default App;
