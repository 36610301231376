import React, {useEffect, useState} from 'react';
import '../styles/main.css';
import Social from './Social'
import MainData from './MainData'
import {readHomeContent} from "../data/firebase_client";

const Main = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [content, setContent] = useState({
        title: '',
        subtitle: '',
        paragraph: '',
        profile_photo: ''
    });
    const [social, setSocial] = useState({
        linkedin: '',
        github: '',
        instagram: '',
        twitter: ''
    });

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const data = await readHomeContent();
                data.paragraph = data.paragraph
                    .replace('%s', data.resume_file)
                    .replace('%s', '/projects');

                setContent(data);
                setSocial(data.social);
                setError('');
            } catch (error) {
                console.error(error);
                setError("An error occurred while fetching data");
            }
        };
        setLoading(true);
        fetchContent().then(
            () => {
                setLoading(false);
            }
        ).catch(
            (_) => {
                setLoading(false);
            }
        );

        const interval = setInterval(() => {
            fetchContent();
        }, 300000);

        return () => clearInterval(interval);
    }, [setLoading, setError]);

    return (
        <section className="home section" id="home">
            {
                loading ? (
             <div className="loading-container">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                 </div>
                ) : (
                    error.length > 0 ? (
                        <p className="error__message">
                            {error}
                        </p>
                    ) : (
                        <div className="home__container container grid">
                            <div className="home__content grid">
                                <Social social={social}/>
                                <div style={{
                                    background: `url(${content.profile_photo}) no-repeat center`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }} className="home__img"/>
                                <div className="home__main__data">
                                    <MainData content={content}/>
                                </div>
                            </div>
                        </div>
                    )
                )
            }
        </section>
    )
};

export default Main;
