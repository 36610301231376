import React, { useState } from "react";
import "../styles/header.css";
import {useLocation} from "react-router-dom";

const Header: React.FC = () => {
    const [Toggle, showMenu] = useState(false);
    const location = useLocation();
    const isActive = (path: string) => location.pathname === path ? 'nav__link active-link' : 'nav__link';

    return (
        <header className="header">
            <nav className="nav container">
                <a href="/" className="nav__logo">Ahmet Hüdai Kaya</a>
                <div className= {Toggle ? "nav__menu show-menu" : "nav__menu"}>
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <a href="/" className={isActive("/")}>
                                <i className="uil uil-estate nav__icon"></i> Home
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="/about" className={isActive("/about")}>
                                <i className="uil uil-user nav__icon"></i> About
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="/projects" className={isActive("/projects")}>
                                <i className="uil uil-arrow nav__icon"></i> Projects
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href="/contact" className={isActive("/contact")}>
                                <i className="uil uil-message nav__icon"></i> Contact
                            </a>
                        </li>
                    </ul>
                    <i className="uil uil-times nav__close" id="nav-close" onClick={() => showMenu(!Toggle)}></i>
                </div>
                <div className="nav__toggle" id="nav-toggle" onClick={() => showMenu(!Toggle)}>
                    <i className="uil uil-apps"></i>
                </div>
            </nav>
        </header>
    )
    
};
export default Header;
